import FundUnicefTemplate from '@/page-blocks/about-us/clear/fund/unicef/FundUnicefTemplate';
import ReportUnicef2021 from '@/page-blocks/about-us/clear/fund/unicef/report.unicef_2021';
import { PageProps } from 'gatsby';
import React from 'react';

const FundUnicef2021: React.FC<PageProps> = (props) => (
  <FundUnicefTemplate year={2021} {...props}>
    <ReportUnicef2021 />
  </FundUnicefTemplate>
);

export default FundUnicef2021;