import { FoundContent } from '@/components/Css/FoundSection';
import {
  Render,
  Article,
  ChartContainer,
  TableContainer,
  NoticeBox,
} from '@/components/Css/ReportKorea';
import DonutChartComponent from '@/components/DonutChart';
import DonutChartMobileComponent from '@/components/DonutChartMobile';
import Section from '@/components/Section';
import SectionHeader from '@/components/SectionHeader';
import { Tit } from '@/components/Titles';
import React from 'react';

const ReportUnicef2021: React.FC = () => (
  <Render>
    <FoundContent>
      <Section>
        <SectionHeader>
          <h2>
            <Tit size="s1">유니세프 예산 구성비</Tit>
          </h2>
        </SectionHeader>

        <Article>
          <h3 className="sky-bd">
            <Tit size="s2" color="sky">
              2021 수입
            </Tit>
          </h3>
          <div className="m-hide">
            <ChartContainer>
              <DonutChartComponent
                centerText="<span style='font-size: 20px; color:#1cabe2;'>총 수입</span><br><b style='font-size: 36px;letter-spacing: -1.6px;color:#1cabe2'>85억 7,000만</b><b style='font-size:20px;color:#828385'> 달러</b>"
                series={[
                  {
                    name: ``,
                    colorByPoint: true,
                    innerSize: `65%`,
                    type: `pie`,
                    data: [
                      { name: `각국 정부 지원금`, y: 64 },
                      { name: `민간 및 비정부 기구 지원금`, y: 24 },
                      { name: `기구간 조정`, y: 9 },
                      { name: `기타(이자수익 등)`, y: 3 },
                    ],
                  },
                ]}
              />
            </ChartContainer>
          </div>
          <div className="pc-hide">
            <ChartContainer>
              <DonutChartMobileComponent
                titleText="<span style='font-size: 16px;color:#1cabe2;'>총 수입</span><br><b style='font-size: 26px;letter-spacing: -1.6px;color:#1cabe2'>85억 7,000만</b><b style='font-size:16px;color:#828385'> 달러</b>"
                series={[
                  {
                    name: ``,
                    colorByPoint: true,
                    innerSize: `50%`,
                    type: `pie`,
                    data: [
                      { name: `각국 정부 지원금`, y: 64 },
                      { name: `민간 및 비정부 기구 지원금`, y: 24 },
                      { name: `기구간 조정`, y: 9 },
                      { name: `기타(이자수익 등)`, y: 3 },
                    ],
                  },
                ]}
              />
            </ChartContainer>
          </div>
          <TableContainer>
            <table className="tbl1">
              <colgroup>
                <col className="col1" />
                <col className="col2" />
                <col className="col3" />
              </colgroup>
              <thead>
                <tr>
                  <th>구분</th>
                  <th>금액(백만 달러)</th>
                  <th>비중</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>각국 정부 지원금</td>
                  <td>
                    <p className="price">5,457</p>
                  </td>
                  <td>
                    <p className="price">64%</p>
                  </td>
                </tr>
                <tr>
                  <td>민간 및 비정부 기구 지원금</td>
                  <td>
                    <p className="price">2,079</p>
                  </td>
                  <td>
                    <p className="price">24%</p>
                  </td>
                </tr>
                <tr>
                  <td>기구간 조정*</td>
                  <td>
                    <p className="price">759</p>
                  </td>
                  <td>
                    <p className="price">9%</p>
                  </td>
                </tr>
                <tr>
                  <td>기타(이자수익 등)</td>
                  <td>
                    <p className="price">275</p>
                  </td>
                  <td>
                    <p className="price">3%</p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>계</strong>
                  </td>
                  <td>
                    <p className="price">8,570</p>
                  </td>
                  <td>
                    <p className="price">100%</p>
                  </td>
                </tr>
              </tbody>
            </table>
          </TableContainer>
          <NoticeBox>
            <ul className="blit-list">
              <li>
                <strong>기구간 조정 : </strong>유니세프를 포함한 여러 국제기구가
                관리하는 유엔 협력 사업비 중 유니세프 분담액
              </li>
            </ul>
          </NoticeBox>
        </Article>

        <Article>
          <h3 className="sky-bd">
            <Tit size="s2" color="sky">
              2021 지출
            </Tit>
          </h3>

          <div className="tbl-wrap">
            <div className="m-hide">
              <ChartContainer>
                <Tit size="s3-1">총지출 구성비</Tit>
                <DonutChartComponent
                  centerText="<span style='font-size: 20px;color:#1cabe2;'>총 지출</span><br><b style='font-size: 36px;letter-spacing: -1.6px;color:#1cabe2'>72억 7,400만</b><b style='font-size:20px;color:#828385'> 달러</b>"
                  series={[
                    {
                      name: ``,
                      colorByPoint: true,
                      innerSize: `65%`,
                      type: `pie`,
                      data: [
                        { name: `사업비`, y: 90.3 },
                        { name: `행정관리비`, y: 5.6 },
                        {
                          name: `국가위원회 모금 및 아동권리 증진활동 투자비`,
                          y: 2.9,
                        },
                        {
                          name: `특별비(보안, 정보 통신 투자비 등)`,
                          y: 1.0,
                        },
                        { name: `유엔개발협력비`, y: 0.1 },
                      ],
                    },
                  ]}
                />
              </ChartContainer>
            </div>
            <div className="pc-hide">
              <ChartContainer>
                <DonutChartMobileComponent
                  titleText="<span style='font-size: 16px;color:#1cabe2;'>총 지출</span><br><b style='font-size: 26px;letter-spacing: -1.6px;color:#1cabe2'>72억 7,400만</b><b style='font-size:16px;color:#828385'> 달러</b>"
                  series={[
                    {
                      name: ``,
                      colorByPoint: true,
                      innerSize: `50%`,
                      type: `pie`,
                      data: [
                        { name: `사업비`, y: 90.3 },
                        { name: `행정관리비`, y: 5.6 },
                        {
                          name: `국가위원회 모금 및 아동권리 증진활동 투자비`,
                          y: 2.9,
                        },
                        {
                          name: `특별비(보안, 정보 통신 투자비 등)`,
                          y: 1.0,
                        },
                        { name: `유엔개발협력비`, y: 0.1 },
                      ],
                    },
                  ]}
                />
              </ChartContainer>
            </div>
            <TableContainer>
              <table className="tbl1">
                <colgroup>
                  <col className="col1" />
                  <col className="col2" />
                  <col className="col3" />
                </colgroup>
                <thead>
                  <tr>
                    <th>구분</th>
                    <th>금액(백만 달러)</th>
                    <th>비중</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>사업비</td>
                    <td>
                      <p className="price">6,571</p>
                    </td>
                    <td>
                      <p className="price">90.3%</p>
                    </td>
                  </tr>
                  <tr>
                    <td>행정관리비</td>
                    <td>
                      <p className="price">408</p>
                    </td>
                    <td>
                      <p className="price">5.6%</p>
                    </td>
                  </tr>
                  <tr>
                    <td>국가위원회 모금 및 아동권리 증진활동 투자비</td>
                    <td>
                      <p className="price">213</p>
                    </td>
                    <td>
                      <p className="price">2.9%</p>
                    </td>
                  </tr>
                  <tr>
                    <td>특별비 (보안, 정보 통신 투자비 등)</td>
                    <td>
                      <p className="price">72</p>
                    </td>
                    <td>
                      <p className="price">1.0%</p>
                    </td>
                  </tr>
                  <tr>
                    <td>유엔개발협력비</td>
                    <td>
                      <p className="price">10</p>
                    </td>
                    <td>
                      <p className="price">0.1%</p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>계</strong>
                    </td>
                    <td>
                      <p className="price">7,274</p>
                    </td>
                    <td>
                      <p className="price">100%</p>
                    </td>
                  </tr>
                </tbody>
              </table>
            </TableContainer>
          </div>

          <div className="tbl-wrap">
            <div className="m-hide">
              <ChartContainer>
                <Tit size="s3-1">분야별 직접 사업비</Tit>
                <DonutChartComponent
                  centerText="<span style='font-size: 20px;color:#1cabe2;'>분야별 직접 사업비</span><br><b style='font-size: 36px;letter-spacing: -1.6px;color:#1cabe2'>71억 3,500만</b><b style='font-size:16px;color:#828385'> 달러</b>"
                  series={[
                    {
                      name: ``,
                      colorByPoint: true,
                      innerSize: `65%`,
                      type: `pie`,
                      data: [
                        { name: `생존과 발달`, y: 29.0 },
                        { name: `교육`, y: 14.0 },
                        { name: `어린이 보호`, y: 8.0 },
                        { name: `안전하고 깨끗한 환경`, y: 12.0 },
                        { name: `공평한 기회 보장`, y: 8.0 },
                        { name: `기타`, y: 16.0 },
                        { name: `행정 관리비`, y: 13.0 },
                      ],
                    },
                  ]}
                />
              </ChartContainer>
            </div>
            <div
              className="pc-hide"
              css={`
                margin-top: 96px;
              `}
            >
              <ChartContainer>
                <DonutChartMobileComponent
                  titleText="<span style='font-size: 16px;color: #1cabe2;'>분야별 직접 사업비</span><br><b style='font-size: 26px;letter-spacing: -1.6px;color:#1cabe2'>71억 3,500만</b><b style='font-size:16px;color:#828385'> 달러</b>"
                  series={[
                    {
                      name: ``,
                      colorByPoint: true,
                      innerSize: `50%`,
                      type: `pie`,
                      data: [
                        { name: `생존과 발달`, y: 29.0 },
                        { name: `교육`, y: 14.0 },
                        { name: `어린이 보호`, y: 8.0 },
                        { name: `안전하고 깨끗한 환경`, y: 12.0 },
                        { name: `공평한 기회 보장`, y: 8.0 },
                        { name: `기타`, y: 16.0 },
                        { name: `행정 관리비`, y: 13.0 },
                      ],
                    },
                  ]}
                />
              </ChartContainer>
            </div>
            <TableContainer>
              <table className="tbl1">
                <colgroup>
                  <col className="col1" />
                  <col className="col2" />
                  <col className="col3" />
                </colgroup>
                <thead>
                  <tr>
                    <th>구분</th>
                    <th>금액(백만 달러)</th>
                    <th>비중</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>생존과 발달</td>
                    <td>
                      <p className="price">2,080</p>
                    </td>
                    <td>
                      <p className="price">29%</p>
                    </td>
                  </tr>
                  <tr>
                    <td>교육</td>
                    <td>
                      <p className="price">2,080</p>
                    </td>
                    <td>
                      <p className="price">14%</p>
                    </td>
                  </tr>
                  <tr>
                    <td>어린이 보호</td>
                    <td>
                      <p className="price">570</p>
                    </td>
                    <td>
                      <p className="price">8%</p>
                    </td>
                  </tr>
                  <tr>
                    <td>안전하고 깨끗한 환경</td>
                    <td>
                      <p className="price">880</p>
                    </td>
                    <td>
                      <p className="price">12%</p>
                    </td>
                  </tr>
                  <tr>
                    <td>공평한 기회 보장</td>
                    <td>
                      <p className="price">590</p>
                    </td>
                    <td>
                      <p className="price">8%</p>
                    </td>
                  </tr>
                  <tr>
                    <td>기타</td>
                    <td>
                      <p className="price">1,140</p>
                    </td>
                    <td>
                      <p className="price">16%</p>
                    </td>
                  </tr>
                  <tr>
                    <td>행정 관리비</td>
                    <td>
                      <p className="price">900</p>
                    </td>
                    <td>
                      <p className="price">13%</p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>계</strong>
                    </td>
                    <td>
                      <p className="price">7,135</p>
                    </td>
                    <td>
                      <p className="price">100%</p>
                    </td>
                  </tr>
                </tbody>
              </table>
            </TableContainer>
          </div>

          <div className="tbl-wrap">
            <div className="m-hide">
              <ChartContainer>
                <Tit size="s3-1">지역별 직접 사업비</Tit>
                <DonutChartComponent
                  centerText="<span style='font-size: 20px;color:#1cabe2;'>지역별 직접 사업비</span><br><b style='font-size: 36px;letter-spacing: -1.6px;color:#1cabe2'>71억 3,500만</b><b style='font-size:20px;color:#828385'> 달러</b>"
                  series={[
                    {
                      name: ``,
                      colorByPoint: true,
                      innerSize: `65%`,
                      type: `pie`,
                      data: [
                        { name: `사하라 이남 아프리카`, y: 42.5 },
                        { name: `중동/북아프리카`, y: 25.7 },
                        { name: `아시아`, y: 17.2 },
                        { name: `유럽/중앙아시아`, y: 5.8 },
                        { name: `라틴아메리카/카리브해`, y: 5.1 },
                        { name: `지역간 조정`, y: 3.6 },
                      ],
                    },
                  ]}
                />
              </ChartContainer>
            </div>
            <div
              className="pc-hide"
              css={`
                margin-top: 96px;
              `}
            >
              <ChartContainer>
                <DonutChartMobileComponent
                  titleText="<span style='font-size: 16px;color:#1cabe2;'>지역별 직접 사업비</span><br><b style='font-size: 26px;letter-spacing: -1.6px;color:#1cabe2'>71억 3,500만</b><b style='font-size:16px;color:#828385'> 달러</b>"
                  series={[
                    {
                      name: ``,
                      colorByPoint: true,
                      innerSize: `50%`,
                      type: `pie`,
                      data: [
                        { name: `사하라 이남 아프리카`, y: 42.5 },
                        { name: `중동/북아프리카`, y: 25.7 },
                        { name: `아시아`, y: 17.2 },
                        { name: `유럽/중앙아시아`, y: 5.8 },
                        { name: `라틴아메리카/카리브해`, y: 5.1 },
                        { name: `지역간 조정`, y: 3.6 },
                      ],
                    },
                  ]}
                />
              </ChartContainer>
            </div>
            <TableContainer>
              <table className="tbl1">
                <colgroup>
                  <col className="col1" />
                  <col className="col2" />
                  <col className="col3" />
                </colgroup>
                <thead>
                  <tr>
                    <th>구분</th>
                    <th>금액(백만 달러)</th>
                    <th>비중</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>중동/북아프리카</td>
                    <td>
                      <p className="price">1,585</p>
                    </td>
                    <td>
                      <p className="price">22%</p>
                    </td>
                  </tr>
                  <tr>
                    <td>서부/중앙아프리카</td>
                    <td>
                      <p className="price">1,401</p>
                    </td>
                    <td>
                      <p className="price">20%</p>
                    </td>
                  </tr>
                  <tr>
                    <td>본부</td>
                    <td>
                      <p className="price">817</p>
                    </td>
                    <td>
                      <p className="price">11%</p>
                    </td>
                  </tr>
                  <tr>
                    <td>남아시아</td>
                    <td>
                      <p className="price">737</p>
                    </td>
                    <td>
                      <p className="price">10%</p>
                    </td>
                  </tr>
                  <tr>
                    <td>동아시아태평양</td>
                    <td>
                      <p className="price">413</p>
                    </td>
                    <td>
                      <p className="price">6%</p>
                    </td>
                  </tr>
                  <tr>
                    <td>라틴아메리카/카리브해</td>
                    <td>
                      <p className="price">406</p>
                    </td>
                    <td>
                      <p className="price">6%</p>
                    </td>
                  </tr>
                  <tr>
                    <td>유럽/중앙아시아</td>
                    <td>
                      <p className="price">386</p>
                    </td>
                    <td>
                      <p className="price">6%</p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>계</strong>
                    </td>
                    <td>
                      <p className="price">7,135</p>
                    </td>
                    <td>
                      <p className="price">100%</p>
                    </td>
                  </tr>
                </tbody>
              </table>
            </TableContainer>

            <NoticeBox>
              <ul className="blit-list">
                <li>
                  유니세프 지출예산은 당해년도 회기 1~2년 전에 집행이사회로부터
                  승인을 받습니다. <br />
                  이로 인해 당해년도의 수입과 지출의 차이가 발생하며, 당해년도
                  차액은 다음 해의 프로그램 예산에 반영됩니다.
                </li>
                <li>
                  각 구분 금액의 합은 반올림에 의해 총 액과 다를 수 있습니다.
                </li>
                <li>
                  출처 :&nbsp;
                  <a
                    href="https://www.unicef.org/reports/unicef-annual-report-2021"
                    className="link-blank"
                    target="_balnk"
                  >
                    &nbsp;UNICEF Annual Report 2021
                  </a>
                </li>
              </ul>
            </NoticeBox>
          </div>
        </Article>
      </Section>
    </FoundContent>
  </Render>
);
export default ReportUnicef2021;
